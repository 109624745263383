import * as React from "react";
import { createRoot } from "react-dom/client";

import { detect } from "detect-browser";
import { isNil } from "lodash";

import { SplashScreen } from "./app/splash";
import { UnsupportedBrowser } from "./app/unsupportedBrowser";

// Load all required CSS now instead of in app.tsx to avoid visual glitches
import "~/assets/image/favicon.ico";
import "react-cover-page/themes/default.css";
import "react-flexible-workbench/themes/dark.css";
import "typeface-roboto";
import "typeface-inconsolata";
import "~/assets/css/app.less";

// Get the root container where the app will be rendered
const reactContainer = document.getElementById("react-root-container");

if (reactContainer === null) {
    throw new Error("Root element of react app cannot be null");
}

const reactRoot = createRoot(reactContainer);

function isBrowserSupported(): boolean {
    const browser = detect();
    if (!isNil(browser) && !isNil(browser.name)) {
        const name = browser.name;
        if (name === "chrome") {
            return true;
        } else if (name === "firefox") {
            return true;
        } else if (name === "edge") {
            return true;
        } else if (name === "edge-chromium") {
            // Microsoft Edge on Mac
            return true;
        }
    }
    return false;
}

interface IInitialAppState {
    appLoadPromise: Promise<void> | undefined;
    isBrowserCompatible: boolean;
}

class InitialApp extends React.Component<Record<string, unknown>, IInitialAppState> {
    constructor(props: Record<string, unknown>) {
        super(props);

        this._MainApp = undefined;
        this.state = { appLoadPromise: undefined, isBrowserCompatible: isBrowserSupported() };
    }

    private _MainApp: React.ComponentType | undefined;

    public componentDidMount(): void {
        const { isBrowserCompatible } = this.state;

        if (isBrowserCompatible) {
            this.setState({ appLoadPromise: this._loadMainApp() });
        }
    }

    private readonly _loadMainApp = async (): Promise<void> => {
        const { App } = await import("./app/app");
        this._MainApp = App;

        this.setState({ appLoadPromise: undefined });
    };

    public render(): React.JSX.Element {
        const { appLoadPromise, isBrowserCompatible } = this.state;

        if (!isBrowserCompatible) {
            return <UnsupportedBrowser />;
        } else {
            if (this._MainApp === undefined) {
                return <>{appLoadPromise !== undefined ? <SplashScreen promise={appLoadPromise} /> : null}</>;
            } else {
                return <this._MainApp />;
            }
        }
    }
}

reactRoot.render(<InitialApp />);
