/**
 * Basic properties of the application as they are presented on the user
 * interface.
 */
export const appDescriptor = {
    name: "Aker",
};

/**
 * Whether the app is being built in production mode. This is set by
 * Webpack at bundling time. In the absence of a Webpack bundler, this
 * will evaluate to `false`.
 */
export const isInProductionMode = process.env.NODE_ENV === "production";

/**
 * The default locale for Numbro and other locale-aware libraries that we
 * might use.
 */
export const defaultLocale = "en-US";

/**
 * Whether API calls should be made only if we are currently authenticated
 * to the API.
 */
export const loginRequired = true;

/**
 * Waiting time between upload status checks.
 */
export const waitingTimeInMilisecondsBeforeUploadStatusCheck = 1000;

/**
 * Max number of iterations in file upload status check.
 */
export const defaultMaxNumberOfIterationsForFileUploadStatusCheck = 5;

/**
 * Event for schedule chart refreshing.
 */
export const scheduleChartRefreshEvent = "scheduleChartRefresh";

/**
 * Wheter the perspective settings menu button is enabled.
 */
export const isPerspectiveSettingsMenuButtonEnabled = true;

/**
 * Constant for workbench in local storage.
 */
export const localStorageConstantForWorkbench = "workbench";

/**
 * Constant for drawers state in local storage.
 */
export const localStorageConstantForDrawers = "drawers";

/**
 * Constant for sidebar state in local storage.
 */
export const localStorageConstantForSidebar = "sidebar";

/**
 * Constant for perspectives state in local storage.
 */
export const localStorageConstantForPerspectives = "perspectives";

/**
 * Constant for selected perspective id in local storage.
 */
export const localStorageConstantForSelectedPerspectiveId = "selectedPerspectiveId";

/**
 * Constant for version of the stored data in local storage.
 */
export const localStorageConstantForLocalStorageVersion = "version";

/**
 * Version number for the stored data in the local storage.
 */
export const localStorageVersion = "2019-03-27";

/**
 * Constant for user data scope change event.
 * Emited by eventBus when user changes its data scope.
 */
export const userDataScopeChangeEvent = "userDataScopeChange";

/**
 * Constant for forecast calculation panel event
 * When forecast panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const forecastCalculationChangeEvent = "forecastCalculationChange";

/**
 * Constant for safety stock calculation panel event
 * When safety stock panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const safetyStockCalculationChangeEvent = "safetyStockCalculationChange";

/**
 * Method key for constant forecast algorithm
 */
export const PREDEFINED_CONSTANT_FORECAST_METHOD = 10001;

/**
 * Method key for constant safety stock algorithm
 */
export const PREDEFINED_CONSTANT_SAFETY_STOCK_METHOD = -1;

/**
 * This constant corresponds to a duration of one day minus 20% in milliseconds. This is
 * needed to fix a bug with overlay barmode in Plotly.js see
 * optimax-web/#43 on Gitlab.
 */
export const FORECAST_SAFETYSTOCK_BAR_WIDTH = 1000 * 60 * 60 * 19.2;

/**
 * WebSocket URL
 */
export const WEBSOCKET_URL: string | undefined = process.env.WEBSOCKET_URL;

/**
 * Socket.IO path (optional)
 */
export const SOCKETIO_PATH: string | undefined = process.env.SOCKETIO_PATH;

/**
 * Filter the perspectives by permissions.
 */
export const ENABLE_PERSPECTIVE_FILTERING_BY_PERMISSIONS = false;

/**
 * Constant for the historical consumption table change event
 */
export const historicalConsumptionTableChangeEvent = "historicalConsumptionTableChangeEvent";

export const defaultScheduleChartRowHeight = 50;

/**
 * Specifies time after which data in globalDataStore should update
 */
export const defaultDataCacheUpdatePeriod = 120;

export enum ProductionTypes {
    Cutting = "cutting",
    Prefab = "production",
    PreAssembly = "pre_assembly",
    Assembly = "assembly",
    Purchasing = "purchasing",
}

export const productionTypesLabels: Record<ProductionTypes, string> = {
    [ProductionTypes.Cutting]: "cutting",
    [ProductionTypes.Prefab]: "prefab",
    [ProductionTypes.PreAssembly]: "pre-assembly",
    [ProductionTypes.Assembly]: "assembly",
    [ProductionTypes.Purchasing]: "purchasing",
};

export const refreshProjectProductionActivityTableEvent = "refreshProjectProductionActivityTable";

export const refreshProjectAssemblyActivityTableEvent = "refreshProjectAssemblyActivityTable";

export const refreshAssemblyConsumableResourceTableEvent = "refreshAssemblyConsumableResourceTable";

export const refreshAssemblyActivityTemplateTableEvent = "refreshAssemblyActivityTemplateTable";

export const refreshAssemblyActivityTableEvent = "refreshAssemblyActivityTable";

export const refreshProductionConsumableResourceTableEvent = "refreshProductionConsumableResourceTable";

export const refreshProductionActivityTableEvent = "refreshProductionActivityTable";

export const refreshStationTableEvent = "refreshStationTable";

export const refreshProductionLineTableEvent = "refreshProductionLineTable";

export const refreshProjectTimelineEvent = "refreshProjectTimeline";

export const selectedProjectLocalStorageKey = "selectedProjectState";

export const refreshUserTableEvent = "refreshUserTable";

export const refreshUserRolesTableEvent = "refreshUserRolesTable";

export const refreshProjectListEvent = "refreshProjectList";

export const defaultStationScheduleRowHeight = 60;

export const refreshProductionActivityTemplatesTableEvent = "refreshProductionActivityTemplatesTable";

export const refreshProductionScheduleChartEvent = "refreshProductionScheduleChart";
